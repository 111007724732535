import React, { useCallback, useContext, useState } from 'react';
import { ANDROID_APP_URL, IOS_APP_URL } from 'shared/constants.platform';
import { showModal } from 'shared/foreground/actions/modals';
import { openFeedsSubMenu } from 'shared/foreground/cmdPalette';
import { globalState } from 'shared/foreground/models';
import { setEmptyStateCategory } from 'shared/foreground/stateUpdaters/transientStateUpdaters/lists';
import { createToast } from 'shared/foreground/toasts.platform';
import copyTextToClipboard from 'shared/foreground/utils/copyTextToClipboard';
import { DisplayTheme, SidebarCategory } from 'shared/types';
import getServerBaseUrl from 'shared/utils/getServerBaseUrl.platform';
import requestWithAuth from 'shared/utils/requestWithAuth.platformIncludingExtension';

import { useCurrentSidebarCategory } from '../../hooks/useCurrentCategory';
import { useIsInboxZero } from '../../hooks/useInboxZero';
import Button from '../Button';
import { FileUploadContext, maxFileSizeMb } from '../FileDropzone';
import AndroidIcon from '../icons/AndroidIcon';
import AppleIcon from '../icons/AppleIcon';
import ChromeIcon from '../icons/ChromeIcon';
import FirefoxIcon from '../icons/FirefoxIcon';
import LogoDarkModeIcon from '../icons/LogoDarkModeIcon';
import LogoIcon from '../icons/LogoIcon';
import SafariIcon from '../icons/SafariIcon';
import StrokeCancelIcon from '../icons/StrokeCancelIcon';
import { discoverWisereadsModalId } from '../Modals/AddWisereadsItemsModal';
import Tooltip from '../Tooltip';
import styles from './RightSidebar.module.css';

const useThemeType = () => {
  const theme = globalState(useCallback((state) => state.client.theme, []));
  return theme === DisplayTheme.Default ? 'light' : 'dark';
};

const BrowserExtensions = () => {
  return (
    <div className={styles.emptyBlock}>
      <h3>Browser extensions</h3>
      <p>
        Install an extension to save articles to your Reader account and to optionally highlight the
        native web.
      </p>
      <div className={styles.buttons}>
        <a
          href="https://chrome.google.com/webstore/detail/jjhefcfhmnkfeepcpnilbbkaadhngkbi"
          target="_blank"
          rel="noopener noreferrer"
          className={`${styles.primary}`}
        >
          <ChromeIcon text="" />
          Chrome
        </a>
        <a
          href="https://addons.mozilla.org/en-US/firefox/addon/readwise-highlighter/"
          target="_blank"
          rel="noopener noreferrer"
          className={`${styles.primary}`}
        >
          <FirefoxIcon text="" />
          Firefox
        </a>
        <a
          href="https://apps.apple.com/app/save-to-reader/id1640236961"
          target="_blank"
          rel="noopener noreferrer"
          className={`${styles.primary}`}
        >
          <SafariIcon className={styles.safariIcon} text="" />
          Safari
        </a>
      </div>
    </div>
  );
};

const MobileApps = () => {
  return (
    <div className={styles.emptyBlock}>
      <h3>Mobile apps</h3>
      <p>
        Install the native mobile apps to read on and save documents from your Android and iOS devices.
      </p>
      <div className={styles.buttons}>
        <a
          href={ANDROID_APP_URL}
          target="_blank"
          rel="noopener noreferrer"
          className={`${styles.primary}`}
        >
          <AndroidIcon />
          Android
        </a>
        <a href={IOS_APP_URL} target="_blank" rel="noopener noreferrer" className={`${styles.primary}`}>
          <AppleIcon />
          iOS
        </a>
      </div>
    </div>
  );
};

const DiscoverWisereads = () => {
  const isDarkMode = globalState(
    useCallback((state) => state.webEffectiveTheme === DisplayTheme.Dark, []),
  );

  const openDiscoverWisereadsModal = useCallback(() => {
    showModal({ id: discoverWisereadsModalId }, { userInteraction: 'click' });
  }, []);

  return (
    <div className={styles.emptyBlock}>
      <h3>Quickly discover new documents</h3>
      <p>
        Want to just jump into reading? You can browse all of the most highlighted documents across the Readwise community here, and easily save them in one click.
      </p>
      <div className={styles.buttons}>
        <Button className={`${styles.primary} ${styles.discoverButton}`} variant="unstyled" onClick={openDiscoverWisereadsModal}>
          {isDarkMode
              ? <LogoDarkModeIcon className={styles.readwiseLogo} />
            : <LogoIcon className={styles.readwiseLogo} />}
          Discover
        </Button>
      </div>
    </div>
  );
};

const EmptyTweetsCategoryContent = () => {
  const themeType = useThemeType();

  return (
    <div className={`${styles.emptyStateContent} ${styles.isEmptyTweetsCategory}`}>
      <img alt="upload" src={`/empty-sidebar-twitter-${themeType}.png`} />
      <div className={styles.emptyBlock}>
        <h3>Compile threads</h3>
        <p>
          Compile Twitter threads into legible documents by either publicly replying “@readwise save
          thread” or privately DMing the tweet to @readwise with the message “t” or “thread”.
        </p>
      </div>
      <div className={styles.emptyBlock}>
        <h3>Connect your Twitter account</h3>
        <p>
          To begin saving Twitter threads,{' '}
          <a
            href="https://readwise.io/twitter_start"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
          >
            connect your account
          </a>{' '}
          in Readwise.
        </p>
      </div>
    </div>
  );
};

const EmptyEmailsCategoryContent = () => {
  const profile = globalState(useCallback((state) => state.client.profile, []));
  const themeType = useThemeType();
  const [emailReminderSent, setEmailReminderSent] = useState(false);

  return (
      <div className={`${styles.emptyStateContent} ${styles.isEmptyEmailsCategory}`}>
        <img alt="upload" src={`/empty-sidebar-email-${themeType}.png`} />
        <div className={styles.emptyBlock}>
          <h3>Forward emails</h3>
          <p>
            You can forward document-like emails to your Library using your custom Reader email address.
            Note that you have two emails: one for Library and one for Feed.
          </p>
          <div className={styles.copyEmailSection}>
            <span className={styles.emailAddress}>{profile?.custom_library_email}</span>
            <button
              className={styles.copyEmailButton}
              type="button"
              onClick={() =>
                profile?.custom_library_email && copyTextToClipboard(profile.custom_library_email)
              }
            >
              Copy
            </button>
          </div>
          <p>
            <button
              className={`${styles.sendReminderButton} ${styles.link}`}
              type="button"
              onClick={() => {
                if (emailReminderSent) {
                  return;
                }
                setEmailReminderSent(true);
                createToast({
                  content: 'Email reminder sent!',
                  category: 'success',
                });
                requestWithAuth(`${getServerBaseUrl()}/reader/api/send_custom_email_onboarding/`, {
                  method: 'POST',
                  credentials: 'include',
                  mode: 'cors',
                });
              }}
            >
              Send yourself a reminder email
            </button>{' '}
            to effortlessly add your custom Reader addresses to your contact list.
          </p>
        </div>
        <div className={styles.emptyBlock}>
          <h3>Email newsletters</h3>
          <p>
            We generally recommend that you direct email newsletters to your Feed, but you can send them
            to your Library instead using the address above.
          </p>
        </div>
      </div>
  );
};

const EmptyArticlesCategoryContent = () => {
  const themeType = useThemeType();

  return (
    <div className={`${styles.emptyStateContent} ${styles.isEmptyArticlesCategory}`}>
      <img alt="upload" src={`/empty-sidebar-browser-${themeType}.png`} />
      <BrowserExtensions />
      <MobileApps />
      <div className={styles.emptyBlock}>
        <h3>Integrations</h3>
        <p>
          Integrate Readwise with{' '}
          <a
            href="https://readwise.io/sync#instapaper"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
          >
            Instapaper
          </a>{' '}
          or{' '}
          <a
            href="https://readwise.io/sync#pocket"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
          >
            Pocket
          </a>{' '}
          to automatically import any documents previously saved there.
        </p>
      </div>
    </div>
  );
};

const EmptyLibraryCategoryContent = () => {
  return (
      <div className={styles.emptyStateContent}>
        <div className={styles.emptyBlock}>
          <h3>Supported document types</h3>
          <p>
            You can save documents of all kinds to Reader including web articles, RSS feeds, email
            newsletters, PDFs, EPUBs, and Twitter threads bringing all your reading into one place.
          </p>
          <p>
            Explore the icons in the left navigation sidebar (or use the keyboard shortcuts{' '}
            <span className={styles.shortcut}>2</span> through <span className={styles.shortcut}>7</span>
            <span>) to learn how to work with documents of each type.</span>
          </p>
        </div>
        <BrowserExtensions />
        <MobileApps />
        <DiscoverWisereads />
      </div>
  );
};

const EmptyFeedCategoryContent = () => {
  const { openFileDialog } = useContext(FileUploadContext);
  const profile = globalState(useCallback((state) => state.client.profile, []));
  const dedicatedEmail = profile?.custom_feed_email;

  return (
      <div className={styles.emptyStateContent}>
        <div className={styles.emptyBlock}>
          <h3>Feed versus Library</h3>
          <p>
            Feed is the default destination for documents that are “pushed” to you by others such as RSS
            subscriptions, email newsletters, and Twitter lists.
          </p>
          <p>
            Feed is separated from your Library to avoid drowning out documents that you&apos;ve
            intentionally chosen to save.
          </p>
        </div>
        <div className={styles.emptyBlock}>
          <h3>RSS feeds</h3>
          <p>
            You can subscribe to RSS feeds a variety of ways. The easiest are to search for the feed
            you&apos;re interested in or upload an OPML file.
          </p>
          <div className={styles.buttons}>
            <Button className={`${styles.primary}`} variant="default" onClick={openFeedsSubMenu}>
              Add feed
            </Button>
            <Button className={`${styles.primary}`} variant="default" onClick={openFileDialog}>
              Browse files
            </Button>
          </div>
        </div>
        {dedicatedEmail &&
          <div className={styles.emptyBlock}>
            <h3>Email newsletters</h3>
            <p>
              You can subscribe to newsletters using your custom Reader email address (or setup an
              auto-forward within your email client).
            </p>
            <div className={styles.dedicatedEmail}>
              <span>{dedicatedEmail}</span>
              <Button
                className={`${styles.primary}`}
                variant="default"
                onClick={() => copyTextToClipboard(dedicatedEmail)}
              >
                Copy
              </Button>
            </div>
            {/* TODO: check if we need to actually hide this */}
            {/* <p><Button variant="unstyled" className={styles.link} onClick={null}>Send yourself a reminder email</Button> to effortlessly add your custom Reader addresses to your contact list.</p> */}
          </div>
        }
      </div>
  );
};

const EmptyPdfsCategoryContent = () => {
  const themeType = useThemeType();
  const { openFileDialog } = useContext(FileUploadContext);

  return (
      <div className={styles.emptyStateContent}>
        <img alt="upload" src={`/empty-sidebar-pdf-${themeType}.png`} />
        <div className={styles.emptyBlock}>
          <h3>Drag and drop</h3>
          <p>
            Upload PDFs by dragging and dropping files from your computer anywhere on top of the web app.
            File sizes up to {maxFileSizeMb} MB are supported.
          </p>
        </div>
        <div className={styles.emptyBlock}>
          <h3>Mobile app share sheet</h3>
          <p>Upload PDFs by launching the share sheet on iOS and selecting Save PDF to Reader.</p>
        </div>
        <div className={styles.emptyBlock}>
          <h3>Email files as attachments</h3>
          <p>Upload PDFs by emailing the files as attachments to your custom Reader email address.</p>
        </div>
        <div className={styles.emptyBlock}>
          <h3>File picker</h3>
          <p>
            <Button
              className={`${styles.browseButton} ${styles.link}`}
              variant="unstyled"
              onClick={openFileDialog}
            >
              Select a PDF file
            </Button>{' '}
            from your computer.
          </p>
        </div>
      </div>
  );
};

const EmptyEpubsCategoryContent = () => {
  const themeType = useThemeType();

  const { openFileDialog } = useContext(FileUploadContext);

  return (
      <div className={styles.emptyStateContent}>
        <img alt="upload" src={`/empty-sidebar-epub-${themeType}.png`} />
        <div className={styles.emptyBlock}>
          <h3>Drag and drop</h3>
          <p>
            Upload EPUBs by dragging and dropping files from your computer anywhere on top of the web
            app. File sizes up to {maxFileSizeMb} MB are supported.
          </p>
        </div>
        <div className={styles.emptyBlock}>
          <h3>Email files as attachments</h3>
          <p>Upload EPUBs by emailing the files as attachments to your custom Reader email address.</p>
        </div>
        <div className={styles.emptyBlock}>
          <h3>File picker</h3>
          <p>
            <Button
              className={`${styles.browseButton} ${styles.link}`}
              variant="unstyled"
              onClick={openFileDialog}
            >
              Select an EPUB file
            </Button>{' '}
            from your computer.
          </p>
        </div>
      </div>
  );
};

const EmptyVideosCategoryContent = () => {
  return (
    <div className={`${styles.emptyStateContent} ${styles.isEmptyVideosCategory}`}>
      <div className={styles.emptyBlock}>
        <h3>Import videos</h3>
        <p>
          Save videos from YouTube, Vimeo, and other platforms using our browser extensions or by sharing
          the video URL through the mobile app. Videos are automatically transcribed and synced with the
          video playback.
        </p>
      </div>
      <div className={styles.emptyBlock}>
        <h3>Interactive transcripts</h3>
        <p>
          Watch videos alongside their transcripts, which are automatically synchronized with the video
          playback. Highlight important segments, take notes, and quickly navigate through the content
          by clicking on any part of the transcript.
        </p>
      </div>
    </div>
  );
};

const EmptyContent: { [key in SidebarCategory]: React.ReactElement } = {
  article: <EmptyArticlesCategoryContent />,
  email: <EmptyEmailsCategoryContent />,
  tweet: <EmptyTweetsCategoryContent />,
  pdf: <EmptyPdfsCategoryContent />,
  epub: <EmptyEpubsCategoryContent />,
  library: <EmptyLibraryCategoryContent />,
  rss: <EmptyFeedCategoryContent />,
  video: <EmptyVideosCategoryContent />,
};

export const EmptyStateSidebarContent = () => {
  const currentCategory = useCurrentSidebarCategory();

  return EmptyContent[currentCategory];
};

const emptyCategoryTitle = (category: SidebarCategory) => {
  const map: { [key in SidebarCategory]: string } = {
    article: 'Save articles',
    email: 'Save emails',
    tweet: 'Save Twitter threads',
    pdf: 'Upload PDFs',
    epub: 'Upload EPUBs',
    library: 'Getting started',
    rss: 'Getting started',
    video: 'Save videos',
  };

  return map[category];
};

export const EmptyStateSidebarHeader = () => {
  const currentCategory = useCurrentSidebarCategory();
  const title = emptyCategoryTitle(currentCategory);

  const { isInboxZero } = useIsInboxZero();

  return (
    <div className={styles.titleContainer}>
      <h1>{title}</h1>
      {!isInboxZero &&
        <Tooltip content="Close" placement="top-start">
          <Button
            onClick={() => setEmptyStateCategory(null, { userInteraction: 'unknown' })}
            className={styles.closeButton}
          >
            <StrokeCancelIcon text="close" />
          </Button>
        </Tooltip>
      }
    </div>
  );
};
